import React from "react";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import ResumeCardTwo from "../components/ResumeCardTwo";

const Experience = () => {

  return (
    <>
      <Seo title="Currículum" />

      <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container sm:px-5 md:px-10 lg:px-14">
            <div className="py-12 px-4 md:px-0">
              <h2 className="after-effect after:left-64">Currículum</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-6 mt-[30px]">
                <ResumeCardTwo />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </section>
    </>
  );
};

export default Experience;

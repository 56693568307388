import React from "react";
import Footer from "../components/Footer";
import AboutCard from "../components/AboutCard";
import SidebarInfo from "../components/SidebarInfo";
import Seo from "../components/Seo";

const AboutTwo = () => {

  return (
    <section>
      <Seo title="About" />

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h2 className="after-effect after:left-72 lg:block hidden">
              Acerca de mí
            </h2>

            <div className="lg:hidden">
              <SidebarInfo />
              <div className=" md:gap-10 mb-12 md:pt-[30px] items-center lg:hidden ">
                <h2 className="after-effect after:left-52 mb-5">Acerca de mí</h2>
                <div className="col-span-12 space-y-2.5">
                  <div className="lg:mr-16">
                    <p className="text-gray-lite  dark:text-color-910 leading-7">
                      Soy Otorrinolaringóloga, en la ciudad de Tijuana, me especializo en enfermedades de oído, nariz, senos paranasales, garganta, laringe y cuello.
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      Este tipo de enfermedades pueden aparecer tanto en niños como en adultos. Mi compromiso es brindar una atención integral, poder contribuir a su salud es una de mis experiencias más satisfactorias a nivel profesional y personal.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite  dark:text-color-910 leading-7">
                    Soy Otorrinolaringóloga, en la ciudad de Tijuana, me especializo en enfermedades de oído, nariz, senos paranasales, garganta, laringe y cuello.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    Este tipo de enfermedades pueden aparecer tanto en niños como en adultos. Mi compromiso es brindar una atención integral, poder contribuir a su salud es una de mis experiencias más satisfactorias a nivel profesional y personal.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="text-[35px] dark:text-white font-medium pb-5">
              Mi especialidad!
            </h3>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">

              <AboutCard />
            </div>
          </div>

          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;

import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Social from './Social.jsx';
import Info from './Info.jsx';
import { WHATSAPP_LINK } from '../helpers';

const SidebarInfo = () => {
  return (
    <div>
      <div className="w-full mb-6 lg:mb-0  mx-auto   relative bg-white text-center dark:bg-[#111111] px-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0 ">
        <img
          src="/images/dra-alejandra-lopez-profile-alt.webp"
          className="w-[240px] absolute left-[50%] transform -translate-x-[50%] h-[240px] drop-shadow-xl mx-auto rounded-[20px] -mt-[140px]"
          alt="Dra. Alejandra López Vidaña"
        />
        {/* Social card */}
        <div className="pt-[100px] pb-8">
          <h1 className="mt-6 mb-1 text-5xl font-semibold  dark:text-white">
            Dra. Alejandra López Vidaña
          </h1>
          <h3 className="mb-4 text-[#7B7B7B] inline-block dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-[#A6A6A6]  ">
            Otorrinolaringóloga
          </h3>

          {/* Social Links */}

          <div className="flex justify-center space-x-3">
            <Social />
          </div>

          {/* personal information */}
          <div className="p-7 rounded-2xl mt-7  bg-[#F3F6F6] dark:bg-[#1D1D1D]">
            <Info />
          </div>

          <a
            href={WHATSAPP_LINK}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center mx-auto bg-gradient-to-r from-[#00E676] to-[#1EBEA5] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#00E676]  to-[#1EBEA5] px-8 py-3 text-lg text-white rounded-[35px] mt-6"
          >
            <FaWhatsapp className='mr-2' /> Whatsapp
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarInfo;

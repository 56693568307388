import React from "react";
import { Helmet } from "react-helmet-async";

const Seo = ({ title, description = 'Soy Otorrinolaringóloga, en la ciudad de Tijuana' }) => {
  return (
    <Helmet>
      <title>
        {title &&
          `${title} | ${description}}`}
      </title>
    </Helmet>
  );
};

export default Seo;

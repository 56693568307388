import {
    FaFacebookF,
    FaPhone,
    FaInstagram,
    FaWhatsapp
} from "react-icons/fa";
import { WHATSAPP_LINK } from '../helpers';

const Social = ({ withWhatsapp = false }) => {
    const socialContent = [
        {
            id: 1,
            link: "https://www.facebook.com/otorrinotijuanaDraAlejandraLopezVidana",
            icon: <FaFacebookF />,
            iconClass: "text-[#1773EA]",
        },
        {
            id: 2,
            link: "https://www.instagram.com/otorrinotijuanadralopez/",
            icon: <FaInstagram />,
            iconClass: "text-[#1C9CEA]",
        },
        {
            id: 3,
            link: "tel:6644783000",
            icon: <FaPhone />,
            iconClass: "text-[#e14a84]",
        },
        {
            id: 4,
            link: WHATSAPP_LINK,
            icon: <FaWhatsapp />,
            iconClass: "text-[#1EBEA5]",
        },
    ];

    return (
        <>
            {socialContent.filter(icon => withWhatsapp ? icon : icon.id !== 4).map((item) => (
                <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={item.id}
                >
                    <span className={`socialbtn ${item.iconClass}`}>{item.icon}</span>
                </a>
            ))}
        </>
    );
};

export default Social;

import React from "react";
import UseData from "../Hooks/UseData";
import { COLORS, capitalize } from '../helpers';

const AboutCard = () => {

  const { local, services } = UseData();

  return (
    <React.Fragment>
      {
        services.map((item, idx) => {
          return (
            <div
              className="about-box dark:bg-transparent"
              key={item.category}
              style={{ background: `${local === "dark" ? "transparent" : COLORS[idx]}` }}
            >
              <img className="w-10 h-10 object-contain block" src={item.icon} alt='' />

              <div className="space-y-2 break-all">
                <h3 className="dark:text-white text-xl font-semibold">{capitalize(item.category)}</h3>
                <p className=" leading-8 text-gray-lite dark:text-[#A6A6A6]">
                  {capitalize(item.description)}
                </p>
              </div>
            </div>
          );
        })
      }
    </React.Fragment>
  );
};
export default AboutCard;

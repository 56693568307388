import { useEffect, useState } from "react";

import { CgNotes } from "react-icons/cg";
import { FaAward, FaRegUser } from "react-icons/fa";
import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }


  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "Inicio",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Experiencia",
      link: "/experience",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Servicios",
      link: "/services",
      icon: <FiCodesandbox />,
    },
    {
      id: "05",
      name: "Contacto",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  const serviceCategories = {
    oído: {
      icon: "/images/icons/ear-icon.webp",
      description: "Tratamiento de afecciones de oído: dolor, sordera, acufeno y vértigo.",
      items: [
        {
          id: "1",
          tag: "oído",
          title: "Otitis",
          img: "/images/services/otitis.webp",
          imgSmall: "otitis.webp",
          bg: "#FFF0F0",
          description:
            "Se le llama otitis a toda infección o inflamación en el oído, dependiendo la ubicación estas se clasifican en externas cuando solo afectan la piel que recubre el conducto auditivo externo o medias cuando es afectada la parte del oído medio. En ocasiones pueden complicarse y hacerse crónicas, dejando como principal secuela la pérdida auditiva, por lo que se recomienda la pronta atención por parte de un especialista",
        },
        {
          id: "2",
          tag: "oído",
          title: "Perdida auditiva",
          img: "/images/services/perdida-auditiva.webp",
          imgSmall: "perdida-auditiva.webp",
          bg: "#FFF3FC",
          description:
            "Es la disminución parcial o total de la audición, tiene múltiples causas, estas pueden ir desde un simple tapón de cerilla hasta casos más severos como un daño en el nervio de la audición.",
        },

        {
          id: "3",
          tag: "oído",
          title: "Tapones de cerumen",
          img: "/images/services/tapon-de-cerilla.webp",
          imgSmall: "tapon-de-cerilla.webp",
          bg: "#FFF0F0",
          description:
            "Es la acumulación de cerumen dentro del conducto auditivo externo, cuando este tapa por completo en conducto puede provocar sordera, dolor por presión de las paredes y en ocasiones se pueden infectar, cuando esto aparece es necesario retirarlos.",
        },
        {
          id: "4",
          tag: "oído",
          title: "Vertigo",
          img: "/images/services/vertigo.webp",
          imgSmall: "vertigo.webp",
          bg: "#E9FAFF",
          description:
            "Es la alteración del equilibrio, donde el paciente siente que las cosas se mueven alrededor o él se mueve, es un síntoma que desencadena terror en el paciente,  en ocasiones puede ser tan intenso que llega a ser incapacitante, por eso es necesario la evaluación completa para encontrar la causa o por lo menos descartar enfermedades que pudieran ser de gravedad",
        },
        {
          id: "5",
          tag: "oído",
          title: "Acufeno/tinnitus",
          img: "/images/services/acufeno.webp",
          imgSmall: "acufeno.webp",
          bg: "#FFFAE9",
          description:
            "Se le llama acufeno a la percepción de ruido o zumbido en el oído, es un síntoma muy molesto que en ocasiones llega a interferir en la vida de los pacientes, impidiéndoles escuchar o conciliar el sueño.",
        },
      ]
    },
    nariz: {
      icon: "/images/icons/nose-icon.webp",
      description: "Tratamiento de la obstrucción nasal, rinitis, sinusitis y cambios externos de la forma de la nariz.",
      items: [{
        id: "6",
        tag: "nariz",
        title: "Rinitis alérgica",
        img: "/images/services/rinitis.webp",
        imgSmall: "rinitis.webp",
        bg: "#F4F4FF",
        description:
          "Es una inflamación de la mucosa nasal, dada por reacción inmunológica del cuerpo a partículas inhaladas que son consideradas extrañas, lo más común son los ácaros, hongos, pelo y caspa de animales, produce síntomas como comezón en nariz, estornudos de manera repetida, congestión nasal y salida de moco transparente.",
      },
      {
        id: "7",
        tag: "nariz",
        title: "Desviacion del tabique nasal",
        img: "/images/services/desviacion-tabique.webp",
        imgSmall: "desviacion-tabique.webp",
        bg: "#F4F4FF",
        description:
          "El tabique nasal es la pared que divide las dos fosas nasales, cuando existe una desviación del tabique nasal, puede ocasionar obstrucción nasal, dolor de cabeza y ronquido, en la mayoría de las veces está indicado su corrección quirúrgica.",
      },
      {
        id: "8",
        tag: "nariz",
        title: "Epistaxis",
        img: "/images/services/epistaxis.webp",
        imgSmall: "epistaxis.webp",
        bg: "#F4F4FF",
        description:
          "Se le llama así al sangrado proveniente de las fosas nasales, pueden deberse a múltiples causas, la más común es el rascado en los niños, pero también puede aparecer cuando hay desviación del tabique nasal, infecciones, alergias, o en casos raros tumores.",
      },
      {
        id: "9",
        tag: "nariz",
        title: "Cuerpos extraños",
        img: "/images/services/cuerpos-extranos.webp",
        imgSmall: "cuerpos-extranos.webp",
        bg: "#F4F4FF",
        description:
          "Los niños de los 2 a los 6 años, son muy curiosos, y pueden introducirse objetos dentro de las cavidades, es común que se introduzcan en la nariz y en el oído objetos mientras juegan, es una urgencia el extraerlos sobre todo si están en nariz o son objetos que puedan liberar sustancias toxicas como las pilas de botón.",
      },
      {
        id: "10",
        tag: "nariz",
        title: "Sinusitis",
        img: "/images/services/sinusitis.webp",
        imgSmall: "sinusitis.webp",
        bg: "#F4F4FF",
        description:
          "Los senos paranasales son unas cavidades de nuestra cara donde normalmente debe de haber aire, cuando la piel que lo recubre se inflama se le llama Sinusitis, y puede haber síntomas como obstrucción nasal, salida de moco o flema frecuente que pasa de la nariz a la garganta, poca percepción de olores o sensación de mal olor en boca o nariz, dolor de cara o de cabeza.",
      },
      {
        id: "11",
        tag: "nariz",
        title: "Ronquido",
        img: "/images/services/ronquido.webp",
        imgSmall: "ronquido.webp",
        bg: "#F4F4FF",
        description:
          "Una de las principales causas del ronquido es la obstrucción de las vías aéreas superiores, una evaluación integral nos ayudara a identificar la causa, para poder dar un tratamiento correcto.",
      },]
    },
    garganta: {
      icon: "/images/icons/throat-icon.webp",
      description: "Infecciones de garganta y amigdalitis.",
      items: [{
        id: "12",
        tag: "garganta",
        title: "Faringitis",
        img: "/images/services/faringitis.webp",
        imgSmall: "faringitis.webp",
        bg: "#F4F4FF",
        description:
          "Se le llama así a la inflamación en la faringe, esta puede deberse a causas infecciosas, la mayoría de ellas virales, y regularmente se pueden presentar dos veces al año, cuando hay síntomas de manera frecuente puede ser originada por alguna proceso que irrite y no tanto que este produciendo una infección, lo mas común reflujo gastroesofagico, respiración oral, sinusitis.",
      },
      {
        id: "13",
        tag: "garganta",
        title: "Adenoamigdalitis / amigdalitis",
        img: "/images/services/adenoamigdalitis.webp",
        imgSmall: "adenoamigdalitis.webp",
        bg: "#F4F4FF",
        description:
          "Las infecciones de amígdalas y adenoides son muy comunes en los niños, llevan a la pérdida de apetito, dolor de garganta, fiebre, mal estado general, mal aliento, falta de descanso, preocupación familiar y tratamiento de alto costo.",
      },
      {
        id: "14",
        tag: "garganta",
        title: "Disfonía",
        img: "/images/services/disfonia.webp",
        imgSmall: "disfonia.webp",
        bg: "#F4F4FF",
        description:
          "Se conoce como disfonía al cambio en el tono de voz, tiene múltiples causas, desde inflamación de las cuerdas vocales por causas de infección o irritación, presencia de lesiones tipo nódulos, pólipos, parálisis de cuerdas vocales o en casos mas graves cáncer.",
      }]
    },
    cirugías: {
      icon: "/images/icons/surgery-icon.webp",
      description: "Cirugía de amígdalas, cirugía de oído, septumplastía y rinoplastia",
      items: [{
        id: "15",
        tag: "cirugías",
        title: "Cirugia de amigdalas y adenoides",
        img: "/images/services/cirugia-amigdalas.webp",
        imgSmall: "cirugia-amigdalas.webp",
        bg: "#F4F4FF",
        description:
          "Es la cirugía más frecuente en el mundo, consiste en remover quirúrgicamente las amígdalas, conocidas también como anginas, y en ocasiones también las adenoides, se indica principalmente cuando hay infecciones de manera frecuente, cuando hay crecimiento y esto ocluye la vía respiratoria provocando ronquido o en ocasiones hasta apnea del sueño..",
      },
      {
        id: "16",
        tag: "cirugías",
        title: "Rinoseptumplastia funcional",
        img: "/images/services/rinoplastia.webp",
        imgSmall: "rinoplastia.webp",
        bg: "#F4F4FF",
        description:
          "Es la cirugía donde se realiza un cambio en el aspecto de la forma externa de la nariz y se corrige la función nasal, es personalizado para cada paciente, y el objetivo es conseguir un perfil facial simétrico.",
      },
      {
        id: "17",
        tag: "cirugías",
        title: "Septumplastia",
        img: "/images/services/septumplastia.webp",
        imgSmall: "septumplastia.webp",
        bg: "#F4F4FF",
        description:
          "Es la cirugía que se encarga de alinear y corregir el tabique nasal, para mejorar la respiración, en esta cirugía no se realiza ningún cambio estético.",
      },
      {
        id: "18",
        tag: "cirugías",
        title: "Cirugia funcional endoscopica de senos paranasales",
        img: "/images/services/cirugia-funcional-endoscopica.webp",
        imgSmall: "cirugia-funcional-endoscopica.webp",
        bg: "#F4F4FF",
        description:
          "En esta cirugía se emplea un instrumento llamado endoscopio que nos permite ver el interior de las fosas nasales y los senos paranasales en una pantalla, a diferencia de la cirugía abierta esta no deja cicatrices en la cara y está indicada para diversos padecimientos, el más común es en el tratamiento quirúrgico de la sinusitis.",
      },
      {
        id: "19",
        tag: "cirugías",
        title: "Cirugia de ronquido/apnea",
        img: "/images/services/cirugia-ronquido.webp",
        imgSmall: "cirugia-ronquido.webp",
        bg: "#F4F4FF",
        description:
          "Este tipo de procedimiento se realiza cuando el paciente refiere que ronca y/o presenta síntomas de apnea del sueño, son diversos procedimientos los que se pueden realizar y dependerá del sitio de la vía aérea donde se presente la obstrucción.",
      },
      {
        id: "20",
        tag: "cirugías",
        title: "Cirugia de oido",
        img: "/images/services/cirugia-oido.webp",
        imgSmall: "cirugia-oido.webp",
        bg: "#F4F4FF",
        description:
          "Son varios los procedimientos quirúrgicos realizados para tratar padecimientos del oído, los más frecuentes son:",
        list: [
          {
            title: "Miringotomia con colocación de tubos de ventilación",
            description: "consiste en realizar una pequeña incisión sobre el tímpano y colocar un tubo, se indica principalmente en la otitis serosa que es cuando hay moco acumulado en el oído medio y no se puede expulsar, también se indica para drenaje de infecciones o colocación de medicamentos"
          },
          {
            title: "Timpanoplastia",
            description: "Es la cirugía indicada para sellar una perforación de la membrana timpánica."
          },
          {
            title: "Mastoidectomia",
            description: "Se realiza principalmente en casos de infecciones crónicas de oído"
          }
        ]
      },
      {
        id: "21",
        tag: "cirugías",
        title: "Biopsias y retiro de verrugas y lunares en piel de cara y cuello",
        img: "/images/services/biopsias.webp",
        imgSmall: "biopsias.webp",
        bg: "#F4F4FF",
        description:
          "Las lesiones localizadas en cabeza y cuello pueden ser removidas para su estudio histopatológico, dependiendo del tamaño y localización, se puede realizar en el consultorio.",
      },]
    },
    procedimientos: {
      icon: "/images/icons/procedure-icon.webp",
      description: "Lavado de oído, extracción de cuerpos extraños, cauterización de sangrados nasales, endoscopia nasal y laríngea.",
      items: [
        {
          id: "22",
          tag: "procedimientos",
          title: "Lavado de oído",
          img: "/images/services/lavado-de-oido.webp",
          imgSmall: "lavado-de-oido.webp",
          bg: "#F4F4FF",
          description:
            "Es un procedimiento muy solicitado, indicado solo en el caso de acumulación de cerumen que obstruya el conducto auditivo, también se realiza en ocasiones para remover cuerpos extraños.",
        },
        {
          id: "23",
          tag: "procedimientos",
          title: "Cauterización de sangrados nasales",
          img: "/images/services/cauterizacion.webp",
          imgSmall: "cauterizacion.webp",
          bg: "#F4F4FF",
          description:
            "Cuando hay sangrados nasales repetidos, en ocasiones es debido a que en la nariz los vasos sanguíneos están muy superficiales y son frágiles, en estos casos se recomienda realizar una cauterización.",
        },
        {
          id: "24",
          tag: "procedimientos",
          title: "Extracción de cuerpos extraños en oído, nariz y garganta",
          img: "/images/services/extraccion-cuerpos-extranos.webp",
          imgSmall: "extraccion-cuerpos-extranos.webp",
          bg: "#F4F4FF",
          description:
            "De los 2 a los 6 años, los niños son muy curiosos, están explorando y es muy común la introducción de objetos en las cavidades ya sea nariz, oído, garganta, es una urgencia el extraerlos, sobre todo si están en nariz o garganta o se trata de objetos que puedan liberar sustancias toxicas como las pilas de botón.",
        },
        {
          id: "25",
          tag: "procedimientos",
          title: "Endoscopía laringea, nasal y de oído",
          img: "/images/services/endoscopia-laringea.webp",
          imgSmall: "endoscopia-laringea.webp",
          bg: "#F4F4FF",
          description:
            "Cuando existen lesiones en nariz y laringe es difícil verlas a simple vista o en ocasiones queremos ver los que el médico nos explica que tenemos, para eso contamos con esta tecnología que nos permite ver en tiempo real nuestro padecimiento.",
        },
      ]
    }
  };

  const services = Object.entries(serviceCategories).map(([category, { description, icon }]) => ({ category, description, icon }));
  const servicesArray = Object.values(serviceCategories)
    .map((service) => service.items)
    .flat(1);
  const categoriesArray = Object.keys(serviceCategories);

  const resumeData = [
    {
      id: 1,
      title: "Educación",
      icon: <MdOutlineBusinessCenter />,
      items: [
        {
          id: 1,
          date: "2021-2023",
          title: "Egresada de la Facultad de Medicina, en la carrera de Medicina General",
          place: "Universidad Autónoma de Sinaloa, México",
          bg: "#FFF4F4",
        },

        {
          id: 2,
          date: "2019 - Present",
          title: "Especialidad de Otorrinolaringología y Cirugía de Cabeza y Cuello en el Antiguo Hospital Civil “Fray Antonio Alcalde”, con Diploma de Especialidad",
          place: "Universidad de Guadalajara",
          bg: "#FFF1FB",
        },

        {
          id: 3,
          date: "2021",
          title: "Cirujana certificada",
          place: "Consejo Mexicano de Otorrinolaringología y Cirugía de Cabeza y Cuello",
          bg: "#FFF4F4",
        },
      ],
    },
    {
      id: 2,
      title: "Experiencia",
      icon: <MdOutlineSchool />,
      items: [
        {
          id: 1,
          date: "2017-2021",
          title: "Miembro activo ",
          place: "Colegio de Otorrinolaringología de Baja California",
          bg: "#EEF5FA",
        },

        {
          id: 2,
          date: "2015-2017",
          title: "Miembro activo ",
          place: "Federación Mexicana de Otorrinolaringología y Cirugía de Cabeza y Cuello",
          bg: "#F2F4FF",
        },

        {
          id: 3,
          date: "2008",
          title: "Miembro activo ",
          place: "Sociedad Mexicana de Otorrinolaringología y Cirugía de Cabeza y Cuello y del Colegio de Otorrinolaringología de Baja California",
          bg: "#EEF5FA",
        },
      ],
    },
    {
      id: 3,
      title: "Awards",
      icon: <FaAward />,
      items: [
        {
          id: 1,
          date: "2015-2017",
          title: "  Graphic Designer",
          place: "Web Graphy, Los Angeles, CA",
          bg: "#FCF4FF",
        },

        {
          id: 2,
          date: "2014 - 2015",
          title: "Jr. Web Developer",
          place: "Creative Gigs.",
          bg: "#FCF9F2",
        },

        {
          id: 3,
          date: "2015-2017",
          title: "Best Freelancer",
          place: "Fiver & Upwork Level 2 & Top Rated",
          bg: "#FCF4FF",
        },
      ],
    },
  ];

  const contactInfo = [
    {
      id: "01",
      icon: "/images/contact/phone-call 1.png",
      title: "Phone ",
      info: (
        <>
          <p>
            <a
              className="text-gray-lite text-lg dark:text-[#A6A6A6] "
              href="tel:+6644783000"
              target='_blank'
              rel="noreferrer"
            >
              +52 (664) 478 3000, Ext.3001
            </a>
          </p>
        </>
      ),
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: "/images/contact/email 1.png",
      title: "Email ",
      info: (
        <>
          <p>
            <a
              className="text-gray-lite text-lg dark:text-[#A6A6A6] email"
              href="mailto:dra.alejandralopez@otorrinotijuana.com"
              target='_blank'
              rel="noreferrer"
            >
              dra.alejandralopez@otorrinotijuana.com
            </a>
          </p>
        </>
      ),
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: "/images/contact/map 1.png",
      title: "Address ",
      info: (
        <>
          <a
            className="text-gray-lite text-lg dark:text-[#A6A6A6] "
            href="https://goo.gl/maps/KoYFH9WtvLLCt7fp8"
            target='_blank'
            rel="noreferrer"
          >
            Antonio Caso 2055, Zona Urbana Rio Tijuana, 22010 Tijuana, B.C., Mexico
          </a>
        </>
      ),
      bg: "#F2F4FF",
    },
  ];

  return {
    categoriesArray,
    check,
    contactInfo,
    handleTheme,
    local,
    menuItemTwo,
    NavLink,
    services,
    servicesArray,
    resumeData,
  };
};

export default AllData;

import React from "react";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import AddressTwo from '../components/AddressTwo';

const Contact = () => {
  return (
    <section className="bg-homeBg dark:bg-homeTwoBg-dark min-h-screen  bg-no-repeat bg-center bg-cover bg-fixed md:pb-16 w-full">
      <Seo Seo="Contáctame" />

      <div className="lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container mb-8  px-4 sm:px-5 md:px-10 lg:px-[60px]">
            <div className="pt-12">
              <h2 className="after-effect after:left-48 mt-12  lg:mt-0 mb-12 md:mb-[30px]">
                Contacto
              </h2>
              <div className="grid-cols-1 mb-[40px] grid gap-x-5 gap-y-7">
                <AddressTwo />
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default Contact;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import './Style.css';
import App from "./App";
import { Helmet, HelmetProvider } from "react-helmet-async";

const description = 'La Dra. Alejandra López es una de las mejores médico Otorrino especialista en oído, nariz y garganta. Llámanos al 664 478 3000 & 664 478 3003, Ext.: 3001';
const title = 'Otorrino Tijuana • Dra. Alejandra Lopéz Vidaña';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Helmet>
      <link rel='icon' type='image/ico' href='/images/favicon.png' sizes='16x16' />
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='www.otorrinotijuana.com' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' key='og:image' content='/images/seo-image.webp' />
    </Helmet>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HelmetProvider>
);
